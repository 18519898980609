<template>
    <modal>
        <template v-slot:title>{{ $t('Add') }}</template>
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
            <b-row>
                <b-col>
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('projectTitle') }}</h4>
                        <input type="text" class="form-control" v-model="form.categoryName" >
                    </div>
                </b-col>
            </b-row>
        </template>
        <template v-slot:footer-bottom>
            <button class="btn link-success fw-medium" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('close') }} </button>
            <button type="button" class="btn btn-primary" v-on:click="add">{{ $t('Add') }}</button>
        </template>
    </modal>
 </template>
 
<script>
import modal from '@/components/modal-small'
import { ApiDocs } from '@/API.js';
import { storeS } from '@/store.js' 
 
 let apiServe = new ApiDocs();
 
export default ({
    data(){
        return {
            form: {
                categoryName: ""
            },
        }
    },
    components: { modal },
    created(){
    },
    methods: {
        add() {
            if (!this.form.categoryName) {
                //перевіряємо чи вказана назва
                this.$toast.error(this.$t('EnterNameTicket'));
            } else {
                apiServe.createApiCategory(this.form).then(result => {
                    if(result.status == 'done'){
                        this.$toast.success(this.$t('сreatedT'));
                        this.$emit('close')
                        this.eventBus.emit('saveApiCategory', true)
                    } else {
                        this.$toast.error(this.$t("error"))
                    }
                })
            }
        },   
    },
    computed: {
        user() {
            return storeS.userbase
        }
    }
 })
</script>