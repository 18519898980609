<template>
    <modal :title="this.$t('project')" @close="$emit('close')" :ifPrevopen="ifPrevopen" :ifNextopen="ifNextopen" @prevOpen="prevOpen" @nextOpen="nextOpen" :stylebody="'var(--vz-body-bg)'">
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email" @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body v-if="keyUpdate == 1">
            <b-card>
                <b-row>
                    <div class="col" style="display:flex;">
                        <!-- <searchBox @searchB="searchB"/> -->
                    </div>
                    <div class="col-auto">
                        <div class="hstack gap-2">
                            <div>
                                <button
                                @click="showCreatedApi = true"
                                class="btn btn-success"
                                ><i class="ri-add-line align-bottom me-1"></i></button
                                >
                            </div>
                        </div>
                    </div>
                </b-row>
            </b-card>
            <div v-if="form.apis" >
                    <b-card v-for="item in form.apis" :key="item" style="padding: 50px;" >
                    <b-row>
                        <b-col lg="6">
                            <h3><b>{{item.name}}</b></h3>
                            <div class="input-group mt-3">
                                <button :class="`btn btn-outline-success text-uppercase`" type="button" >{{item.type}}</button>
                                <input type="text" class="form-control" v-model="item.url" disabled>
                                <button class="btn btn-primary" type="button">Copy</button>
                            </div>
                            <table class="table table-nowrap mt-5">
                                <thead>
                                    <tr>
                                        <th scope="col " style="text-align:right;font-size: 16px;">{{$t('attribute')}}</th>
                                        <th scope="col" style="font-size: 16px;">{{$t('desc')}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="value in item.attributes" :key="value">
                                        <td style="text-align:right;font-size:14px"><b>{{ value.name }}</b> <br />
                                            <span class="text-muted">{{ value.type }}</span><br />
                                            <span class="text-muted">{{ value.required == 1 ? $t('Required') : $t('NotRequired')}}</span>
                                        </td>
                                        <td>{{ value.comment }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </b-col>
                        <b-col lg="6">
                            <!-- <div class="code-view">
                                <pre class="language-json">
                                    <code class="language-json">{{ this.data }}</code> 
                                </pre>
                            </div> -->
                        </b-col>
                    </b-row>
                </b-card>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <button class="btn link-danger fw-medium"  @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i>{{ $t('close') }}</button>
        </template>
    </modal>

    <createdApi 
        v-if="showCreatedApi"
        @close="showCreatedApi = false"
    />

</template>

<script>
import modal from '@/components/modal'
import createdApi from '../apidocs/create'
import { ApiDocs } from '@/API.js'
import { storeS } from '@/store';

let apiServe = new ApiDocs();

export default{
    props: ['objCard', 'ifPrevopen', 'ifNextopen'],
    components: { modal, createdApi },
    data(){
        return{
            form:"",
            showModal: "",
            showCreatedApi: false,
            boxprojects: false,
            boxtickets: false,
            data:{},
            keyUpdate: 1,
            objParams:{
                page: '1',
                pagelimit: '100',
                search: ''
            },
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            this.form = this.objCard
        },
        updateCard(){
            this.keyUpdate = 0
            apiServe.getApiCategory(this.objCard.apiCategoryId).then(result => {
                if(result.status === 'done') {
                    if(this.keyUpdate == 0){
                        this.form = result.data;
                        this.keyUpdate = 1
                    }
                }
            })
        },
        toDelete(){
            apiServe.deleteCategory(this.form.apiCategoryId).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('Removed'));
                    this.eventBus.emit('saveApiCategory', true)
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('error'));
                }
            })
        },
        prevOpen(){
            this.$emit('close');
            this.$emit('prevOpen', this.form.apiCategoryId)
        },
        nextOpen(){
            this.$emit('close');
            this.$emit('nextOpen', this.form.apiCategoryId)
        },
    },
    mounted(){
        this.eventBus.on('saveApi', (status) => {
            if(status == true) {
                this.updateCard();
            }
        })
    },
    computed: {
        perms(){
            return storeS.perms
        }
    }
}
</script>